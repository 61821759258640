<template>
	<div class="app-container">
		<div class="app-top-pane flex align-center">
			<h2>Appointments</h2>

            <div class="select-box">
                <select id="appSelect" class="app-select" v-model="appType">
                    <option value="all" selected>All </option>
                    <option value="offline">Offline</option>
                    <option value="visitshop">Visit shop</option>
                </select>
                <div class="icon-sprite app-select-icon"></div>
            </div>
		</div>

        <AppointmentTable
            v-if=" appType === 'all' "
            :appointments="allAppointments" />
        <AppointmentTable
            v-if=" appType === 'visitshop' "
            :appointments="visitShopApps" />
        <AppointmentTable
            v-if=" appType === 'offline' "
            :appointments="offlineApps" />
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import AppointmentTable from '@/components/admin/Appointments/AppointmentTable.vue';

export default {
    data() {
        return {
            appType: 'all'
        }
    },
    components: {
        AppointmentTable,
    },
    computed: {
        ...mapGetters([
            'allAppointments',
            'offlineApps',
            'visitShopApps'
        ])
    }
};
</script>

<style scoped>
.app-container {
    position: absolute;
    top: 0;
    left: 90px;
    height: 100vh;
    width: calc(100% - 90px);
    background-color: #fff;
}

.app-top-pane {
    height: 80px;
    border-bottom: 2px solid #f1f1f1;
    padding: 0 25px;
    box-shadow: 1px 1px 4px rgb(0, 0, 0, 0.1);
}

h2 {
    font-weight: 800;
    font-size: 26px;
}

.select-box
{
    margin-left: 50px;
    height: 42px;
    width: 150px;
    position: relative;
    cursor: pointer;
}

.app-select
{
    height: 100%; width: 100%;
    padding: 3px 12px;
    border-radius: 12px;
    outline: none;
    border: 3px solid #323232;
    font-size: 16px;
    line-height: 2;
    font-weight: 500;
    box-shadow: 1px 1px 5px rgb(0, 0, 0, 0.1);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.app-select-icon
{
    background-size: 100.7px 70.3px;
    background-position: -64.7px -2.8px;
	width: 18px; height: 9px;
    pointer-events: none;
    position: absolute;
    top: 50%; right: 18px;
    transform: translateY(-50%);
}

</style>
