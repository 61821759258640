<template>
<div>
    <SideBar />
    <AppointmentContainer />
</div>
</template>

<script>
import SideBar from '@/components/admin/SideBar.vue';
import AppointmentContainer from '@/components/admin/Appointments/AppointmentContainer.vue';
import { Appointments } from '../../services/Service';

export default {
    components: {
        SideBar,
        AppointmentContainer
    },
    methods: {
        async fetchAllAppointments() {
            const response = await Appointments.getAll();
            this.$store.dispatch('storeAppointments', response.data.result);
        }
    },
    created() {
        this.fetchAllAppointments();
    }
}
</script>

<style scoped>

</style>