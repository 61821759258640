<template>
<div>

    <div class="appointment-table"
        v-if=" !$store.state.appointmentsStored "
    >
        <div class="appointment-header-row flex justify-btw align-center">
            <span class="appointment-id">
                ID
                <div class="sort-icon" data-name="appointmentID" @click="sort"></div>
            </span>
            <span class="date">
                Date
                <div class="sort-icon" data-name="date" @click="sort"></div>
            </span>
            <span class="time"> Time </span>
            <span class="type">
                type
                <div class="sort-icon" data-name="type" @click="sort"></div>
            </span>
            <span class="name">
                Name
                <div class="sort-icon" data-name="customerName" @click="sort"></div>
            </span>
            <span class="email">
                Email
            </span>
            <span class="mobile-no">
                Mobile No.
            </span>
            <span class="booked-date">
                Booked Date
                <div class="sort-icon" data-name="bookedOn" @click="sort"></div>
            </span>
        </div>

        <div class="appointment-row flex justify-btw align-center">
            <span class="appointment-id"> <i class="pseudo-loader"></i>  </span>
            <span class="date"> <i class="pseudo-loader"></i> </span>
            <span class="time"> <i class="pseudo-loader"></i> </span>
            <span class="type"> <i class="pseudo-loader"></i> </span>
            <span class="name"> <i class="pseudo-loader"></i> </span>
            <span class="email"> <i class="pseudo-loader"></i> </span>
            <span class="mobile-no"> <i class="pseudo-loader"></i> </span>
            <span class="booked-date"> <i class="pseudo-loader"></i> </span>
        </div>

        <div class="appointment-row flex justify-btw align-center">
            <span class="appointment-id"> <i class="pseudo-loader"></i>  </span>
            <span class="date"> <i class="pseudo-loader"></i> </span>
            <span class="time"> <i class="pseudo-loader"></i> </span>
            <span class="type"> <i class="pseudo-loader"></i> </span>
            <span class="name"> <i class="pseudo-loader"></i> </span>
            <span class="email"> <i class="pseudo-loader"></i> </span>
            <span class="mobile-no"> <i class="pseudo-loader"></i> </span>
            <span class="booked-date"> <i class="pseudo-loader"></i> </span>
        </div>

        <div class="appointment-row flex justify-btw align-center">
            <span class="appointment-id"> <i class="pseudo-loader"></i>  </span>
            <span class="date"> <i class="pseudo-loader"></i> </span>
            <span class="time"> <i class="pseudo-loader"></i> </span>
            <span class="type"> <i class="pseudo-loader"></i> </span>
            <span class="name"> <i class="pseudo-loader"></i> </span>
            <span class="email"> <i class="pseudo-loader"></i> </span>
            <span class="mobile-no"> <i class="pseudo-loader"></i> </span>
            <span class="booked-date"> <i class="pseudo-loader"></i> </span>
        </div>

        <div class="appointment-row flex justify-btw align-center">
            <span class="appointment-id"> <i class="pseudo-loader"></i>  </span>
            <span class="date"> <i class="pseudo-loader"></i> </span>
            <span class="time"> <i class="pseudo-loader"></i> </span>
            <span class="type"> <i class="pseudo-loader"></i> </span>
            <span class="name"> <i class="pseudo-loader"></i> </span>
            <span class="email"> <i class="pseudo-loader"></i> </span>
            <span class="mobile-no"> <i class="pseudo-loader"></i> </span>
            <span class="booked-date"> <i class="pseudo-loader"></i> </span>
        </div>

    </div>

    <div class="appointment-table" v-if="$store.state.appointmentsStored " >
        <div class="appointment-header-row flex justify-btw align-center">
            <span class="appointment-id">
                ID
                <div class="sort-icon" data-name="appointmentID" @click="sort"></div>
            </span>
            <span class="date">
                Date
                <div class="sort-icon" data-name="date" @click="sort"></div>
            </span>
            <span class="time"> Time </span>
            <span class="type">
                type
                <div class="sort-icon" data-name="type" @click="sort"></div>
            </span>
            <span class="name">
                Name
                <div class="sort-icon" data-name="customerName" @click="sort"></div>
            </span>
            <span class="email">
                Email
            </span>
            <span class="mobile-no">
                Mobile No.
            </span>
            <span class="booked-date">
                Booked Date
                <div class="sort-icon" data-name="bookedOn" @click="sort"></div>
            </span>
        </div>

        <AppointmentRow
            v-for="appointment in pageappointments"
            :key="appointment.appointmentID"
            :appointment="appointment"
        />

        <p v-if="appointments.length === 0" class="no-order flex center">
            There are no appointments.
        </p>

    </div>

    <div class="appointments-menu-row flex align-center">

        <div class="pagination flex justify-btw align-center" v-if="1 < noOfPages" >

            <div class="icon-sprite prev-page-icon"
                :class="{ 'hide': pageNo === 1 }"
                @click="pageNo--" ></div>

            <p class="page-show">
                Page {{ pageNo }} of {{ noOfPages }}
            </p>

            <div class="icon-sprite next-page-icon"
                :class="{ 'hide': pageNo === noOfPages }"
                @click="pageNo++" ></div>
        </div>
    </div>

</div>
</template>

<script>
import AppointmentRow from '@/components/admin/Appointments/AppointmentRow.vue';
import $ from 'jquery';
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            pageNo: 1
        }
    },
    components: {
        AppointmentRow,
    },
    props: {
        appointments: {
            type: Array,
            required: true
        }
    },
    methods: {
        sort(e) {

            var { name } = e.target.dataset;
            console.log( name );
            var ascorder = $(e.target).hasClass('asc-appointment');
            this.appointments.sort( (a,b) => {

                if( !ascorder ) {
                    if( a[name] < b[name] ) { return -1; }
                    if( a[name] > b[name] ) { return 1; }
                } else {
                    if( a[name] < b[name] ) { return 1; }
                    if( a[name] > b[name] ) { return -1; }
                }

                return 0;
            });
            $('.asc-appointment').removeClass('asc-appointment');
            if( ascorder ) { $(e.target).removeClass('asc-appointment'); }
            else { $(e.target).addClass('asc-appointment'); }
        }
    },
    computed: {
        noOfPages() {
            return Math.ceil(this.appointments.length / 20)
        },
        pageappointments() {
            var start = (this.pageNo - 1) * 20;
            return this.appointments.slice(start, start + 20);
        },
        ...mapGetters(['allAppointments'])
    }
}
</script>

<style scoped>

.appointments-menu-row
{
    height: 80px;
    border-bottom: 2px solid #f1f1f1;
    padding: 0 25px;
    box-shadow: 1px 1px 4px rgb(0, 0, 0, 0.1);
    background-color: #fff;
}

.appointment-table
{
    margin: 30px auto;
    border: 3px solid #000;
    height: calc(100vh - 220px);
    overflow-y: auto;
    overflow-x: auto;
    width: calc(100% - 50px);
    background-color: #fff;
}

.appointment-header-row
{
    height: 40px;
    padding: 0 15px;
    border-bottom: 3px solid #000;
    text-transform: capitalize;
    position: -webkit-sticky;
    position: sticky;
    top: 0; left: 0;
    background-color: grey;
    z-index: 2;
    min-width: 1370px;
}

.appointment-header-row span
{
    width: calc(100% / 8);
    font-weight: 600;
    font-size: 12.5px;
    line-height: 20px;
    padding-right: 25px;
    color: #fff;
    cursor: pointer;
    position: relative;
}

.sort-icon
{
    position: absolute;
    top: 50%; right: 10px;
    transform: translateY(-50%);
    border-top: 6px solid white;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    display: none;
}

.appointment-header-row span:hover .sort-icon
{
    display: block;
}

.sort-icon.asc-appointment
{
    transform: translateY(-50%) rotate(180deg);
}

span.appointment-id,
span.time
{ width: 110px; }

span.date,
span.booked-date,
span.type,
span.name,
span.email,
span.mobile-no
{ width: 175px; }

.page-show
{
    font-size: 16px;
    line-height: 1;
    font-weight: 600;
    margin: 0 30px;
}

.pagination
{
    margin-left: auto;
}

.icon-sprite.prev-page-icon, .icon-sprite.next-page-icon
{
    background-size: 421.2px 294.1px;
    background-position: -382.4px -32px;
	height: 30px; width: 30px;
    cursor: pointer;
    filter: drop-shadow(0px 0px 2px rgb(0,0,0,0.1));
    transition: transform 0.2s ease;
}

.icon-sprite.next-page-icon{ transform: rotate(180deg); }

.prev-page-icon:hover { transform: scale3d(1.1,1.1,1.1); }

.next-page-icon:hover { transform: rotate(180deg) scale3d(1.1,1.1,1.1); }

.hide
{
    visibility: hidden;
}

p.no-order
{
    font-size: 35px;
    height: calc(100% - 40px);
}


.appointment-row
{
    min-height: 60px;
    padding: 9px 15px;
    border-bottom: 2px solid #000;
    cursor: pointer;
    min-width: 1370px;
}

.appointment-row span i { height: 20px; width: 75% !important; }


</style>