<template>
    <div class="appointment-row flex justify-btw align-center" >
        <span class="appointment-id"> {{ appointment.appointmentID }} </span>
        <span class="date"> {{ makeDate(appointment.date) }} </span>
        <span class="time"> {{ appointment.time }} </span>
        <span class="type" :class="classObj">
            <i> {{ appointment.type }} </i>
        </span>
        <span class="name"> {{ appointment.customerName }} </span>
        <span class="email"> {{ appointment.customerEmail }} </span>
        <span class="mobile-no"> {{ appointment.mobileNumber }} </span>
        <span class="booked-date"> {{ makeDate(appointment.bookedOn) }} </span>
    </div>
</template>

<script>
	export default {
        data() {
            return {
                months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
            }
        },
		props: {
			appointment: {
				type: Object,
				required: true
			}
        },
        computed: {
            classObj() {
                return {
                    "offline": this.appointment.type === "offline",
                    "visit-shop": this.appointment.type === "visitshop"
                }
            },
            makeDate() {
                return date => {
                    date = typeof date === "string" ? new Date(date) : date;
                    return date.toLocaleDateString('en-IN', { month: 'long', day: 'numeric' });
                }
            },
        },
	};
</script>

<style scoped>

.appointment-row
{
    min-height: 60px;
    padding: 9px 15px;
    border-bottom: 2px solid #000;
    cursor: pointer;
    min-width: 1370px;
}

span
{
    width: calc(100% / 8);
    padding-right: 25px;
    font-weight: 600;
    font-size: 14.5px;
    line-height: 1.5;
    color: black;
}

.appointment-id, .brand, .model { width: 100px; }

.issue { min-width: 250px; }

.expected-delivery-date { width: 165px; }

.appointment-id
{
    font-family: 'Poppins', sans-serif;
    font-size: 13px;
}

.model, .c-name, .issue
{
    text-transform: capitalize;
}

.appointment-row span.appointment-id,
.appointment-row span.time
{ width: 110px; }

.appointment-row span.date,
.appointment-row span.booked-date,
.appointment-row span.type,
.appointment-row span.name,
.appointment-row span.email,
.appointment-row span.mobile-no
{ width: 175px; }


.type { padding: 0; }

.type i
{
    padding: 10px 20px;
    display: inline-block;
    border-radius: 15px;
    color: whitesmoke;
    font-style: normal;
    text-transform: capitalize;
    line-height: 1;
}

.type.offline i { background-color: #00917c; }

.type.visit-shop i { background-color: #51c2d5; }

</style>
